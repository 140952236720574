
import { Options, Vue } from 'vue-class-component';
import { FriendRequestClient } from '@/services/Services';
import ChatHeader from '../components/chatHeader.vue';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        ChatHeader,
    }
})
export default class ChatNewRequest extends Vue {

    vm: OM.MakeFriendRequestVm = new OM.MakeFriendRequestVm();
    isSending = false;
    placeholder: string = "";

    created() {
        this.placeholder = LocalizationServices.getLocalizedValue("app_scriviqualcosa", "Scrivi qualcosa");
        this.vm.userIdentifier = this.$route.params.userIdentifier.toString();        
    }

    send() {
        if(this.isSending)
            return;

        this.isSending = true;

        FriendRequestClient.makeFriendRequest(this.vm, false)
        .then(x => {
            this.$router.replace('/chats/request/' + x);
        }).finally( () => {
            this.isSending = false;
        })
    }

    openDetail() {
        this.$router.push('/profile/' + this.vm.userIdentifier);
    }

}
